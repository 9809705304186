import { ReactNode } from "react"
import RenderIfVisible from "react-render-if-visible"
import useMediaQuery from "utils/useMediaQuery"

interface Props {
  i: number
  children: ReactNode
  defaultHeight?: number
  visibleOffset?: number
}

const MountIfVisible = (props: Props) => {
  const { i, children, ...rest } = props
  const isDesktop = useMediaQuery("(min-width: 900px)")
  const limit = isDesktop ? 8 : 3
  return (
    <div>
      {i > limit ? (
        <RenderIfVisible {...rest}>{children}</RenderIfVisible>
      ) : (
        children
      )}
    </div>
  )
}

export default MountIfVisible

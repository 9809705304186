import { Price } from "types/types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface State {
  price: Price | ""
  setPrice: (price: Price | "") => void

  feedPrice: Price | ""
  setFeedPrice: (price: Price | "") => void

}

const useFiltersStore = create<State>()(
  devtools(
    (set) => ({
      price: "",
      setPrice: (price: Price | "") => set((state) => ({ ...state, price })),
      feedPrice: "",
      setFeedPrice: (feedPrice: Price | "") => set((state) => ({ ...state, feedPrice })),
    }),
    {
      name: "filters-store",
    },
  ),
)

export default useFiltersStore

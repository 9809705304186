import thumbnail from "./appollow.jpg"
const addToHomeScreen = {
  en: {
    href: "/articles/add-appollow-to-your-home-screen",
    title: "Install Appollow on your iPhone",
    subtitle:
      "Get the benefits of using Appollow just like any other app on your home screen",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/add-appollow-to-your-home-screen",
    title: "قم بتثبيت Appollow على هاتفك",
    subtitle:
      "تمتع باستخدام Appollow تمامًا مثل أي تطبيق آخر على شاشتك الرئيسية",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/add-appollow-to-your-home-screen",
    title: "Appollow'u ana ekranınıza kurun",
    subtitle:
      "Ana ekranınızdaki diğer tüm uygulamalar gibi Appollow kullanmanın avantajlarından yararlanın",
    thumbnail: thumbnail.src,
  },
}

export default addToHomeScreen

import ReactDOMServer from "react-dom/server"
import { useEffect } from "react"
import PullToRefresh from "pulltorefreshjs"
import { FaArrowDown } from "react-icons/fa"
import { event } from "./gtag"
import useRouterStore from "stores/routerStore"

interface Props {
  refetch: () => Promise<any>
  element: string
  page: "home" | "apps" | "games" | "allApps"
}
export default function usePullToRefresh({
  refetch,
  element,
  page = "home",
}: Props) {
  const appId = useRouterStore((state) => state.router?.query.appId)
  const handleFetch = async () => {
    event({
      action: "pull_to_refresh",
      category: "Refresh",
      label: `${page} page pull to refresh`,
      value: `${page} page`,
    })
    return await refetch()
  }

  useEffect(() => {
    // disable pull to refresh when app preview page is open
    if (appId) {
      PullToRefresh.destroyAll()
      return
    }
    PullToRefresh.init({
      mainElement: element,
      onRefresh: handleFetch,
      distThreshold: 70,
      distMax: 90,
      distReload: 80,
      iconArrow: ReactDOMServer.renderToString(<FaArrowDown />),
      iconRefreshing: ReactDOMServer.renderToString(
        <div
          className="font-10 spinner-border spinner-border-sm"
          role="status"
        />,
      ),
    })
    return () => PullToRefresh.destroyAll()
  }, [element, appId])

  return {}
}

import thumbnail from "./thumbnail.jpg"
const MoviesApps = {
  en: {
    href: "/articles/movies-apps",
    title: "Best Movies & TV Shows Apps",
    subtitle: "Enjoy watching movies and TV shows for free on iPhone and iPad.",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/movies-apps",
    title: "تطبيقات لمشاهدة الافلام والمسلسلات",
    subtitle: "شاهد الافلام والمسلسلات مع هذه التطبيقات من الآبستور",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/movies-apps",
    title: "Film ve dizi izlemek için uygulamalar",
    subtitle:
      "iPhone ve iPad'de ücretsiz film ve dizi izlemenin keyfini çıkarın.",
    thumbnail: thumbnail.src,
  },
}

export const Movies2WatchApps = {
  en: {
    href: "/articles/movies2watch-movies-apps",
    title:
      "movies2watch alternative apps to watch movies for free on iPhone and iPad",
    subtitle:
      "Enjoy watching movies2watch movies and TV shows for free on iPhone and iPad.",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/movies2watch-movies-apps",
    title:
      "تطبيقات بديلة لموقع movies2watch لمشاهدة الافلام والمسلسلات مجاناً على الآيفون والآيباد",
    subtitle:
      "شاهد الافلام والمسلسلات من موقع movies2watch مع هذه التطبيقات من الآبستور",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/movies2watch-movies-apps",
    title:
      "Film izlemek için iPhone ve iPad'e yönelik movie2watch alternatif uygulamaları",
    subtitle:
      "iPhone ve iPad'de ücretsiz movies2watch'ten film ve dizi izlemenin keyfini çıkarın.",
    thumbnail: thumbnail.src,
  },
}

export default MoviesApps

import thumbnail from "./thumbnail.jpg"
const BestNoteTakingApps = {
  en: {
    href: "/articles/best-note-taking-apps",
    title: "Best Note Taking Apps For iPad",
    subtitle:
      "If you want to get the most out of your iPad, check out our picks for the best note apps.",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/best-note-taking-apps",
    title: "افضل تطبيقات الملاحظات للآيباد",
    subtitle:
      "هل تحتاج الى تطبيقات احترافية لتدوين الملاحظات؟ تعرف على افضلها للآيباد.",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/best-note-taking-apps",
    title: "iPad için en iyi not alma uygulamaları",
    subtitle:
      "Profesyonel not alma uygulamalarına mı ihtiyacınız var? iPad için en iyileri hakkında bilgi edinin.",
    thumbnail: thumbnail.src,
  },
}

export default BestNoteTakingApps

import { Children, ReactElement, useEffect, useState } from "react"
import Image from "next/image"
import { AppInterface } from "types/types"
import useT from "utils/useT"
import Link from "next/link"
import displaySubmitterNote from "utils/displaySubmitterNote"
import useFetchApps from "utils/useFetchApps"
import { fetchAll } from "queries"
import MountIfVisible from "components/MountIfVisible"
import useRouterStore from "stores/routerStore"
import imagePlaceholder from "utils/imagePlaceholder"
import useGlobalStore from "stores/global"
import getLink from "utils/getLink"
interface AppCardProps {
  app: AppInterface
  i: number
}
function Name({ app, i }: AppCardProps) {
  const t = useT()
  const isDark = useGlobalStore((state) => state.isDark)
  const pathname = useRouterStore((state) => state.router?.pathname)
  const query = useRouterStore((state) => state.router?.query)
  const {
    submitterPrice,
    screenshotUrls,
    artworkUrl512,
    price,
    submitters,
    trackId,
    trackName,
    description,
  } = app
  const submitter = submitters?.[0]
  const showDiscountedPrice = Number(price) < Number(submitterPrice || 0)

  return (
    <div style={{ minWidth: "230px", height: 396 }}>
      <MountIfVisible i={i} visibleOffset={-80}>
        <Link
          href={
            trackId
              ? {
                  pathname: pathname,
                  query: {
                    ...query,
                    appId: trackId,
                  },
                }
              : ""
          }
          as={trackId ? getLink(trackId, trackName) : ""}
          scroll={false}
        >
          <div
            className="splide px-3 relative"
            style={{ visibility: "visible" }}
          >
            <div
              className="card rounded-l shadow-l"
              style={{ height: 300, marginBottom: "6rem" }}
            >
              <div className="card-bottom">
                <p dir="auto" className="slider-app-description">
                  {displaySubmitterNote({ submitter, description })}
                </p>
              </div>
              <div className="card-overlay bg-gradient-fade"></div>
              <Image
                className="card-bg"
                src={screenshotUrls?.[0] || imagePlaceholder(isDark)}
                alt="screenshot"
                fill
                sizes="198px"
                style={{ objectFit: "cover" }}
                priority={Boolean(i && i < 4)}
                placeholder="blur"
                blurDataURL={imagePlaceholder(isDark)}
                unoptimized
              />
            </div>
            <div className="app-item slider-app-item">
              <div className="slider-app-icon shadow-l app-img">
                <Image
                  className="app-img"
                  width={80}
                  height={80}
                  src={artworkUrl512 || imagePlaceholder(isDark)}
                  alt="app icon"
                  priority={Boolean(i && i < 4)}
                  placeholder="blur"
                  blurDataURL={imagePlaceholder(isDark)}
                  unoptimized
                />
              </div>
              <p className="slider-app-name">
                {app?.trackName || "Loading..."}
              </p>
              {showDiscountedPrice && (
                <span className="slider-old-price">${submitterPrice}</span>
              )}
              <span className="slider-app-btn">
                {price === 0
                  ? t.previewApp.free
                  : price
                  ? `$${price}`
                  : t.previewApp.view}
              </span>
            </div>
          </div>
        </Link>
      </MountIfVisible>
    </div>
  )
}
export default function FeaturedSlider(): ReactElement {
  const t = useT()
  const [showList, setShowList] = useState(true)
  const locale = useRouterStore((state) => state.router?.locale)
  const isArabic = locale?.includes("ar")
  const { data: featuredApps } = useFetchApps({
    queryFn: ({ pageParam }) => fetchAll({ price: "discounted", pageParam }),
    queryKey: ["getAll" + "discounted"],
  })
  useEffect(() => {
    if (featuredApps?.length === 0) {
      setShowList(false)
    }
  }, [featuredApps?.length])

  if (showList)
    return (
      <div>
        <div className="content mb-3">
          <h2
            className={`float-${isArabic ? "end" : "start"} font-16 font-500`}
            dir="auto"
          >
            {t.home.discounted}
          </h2>
          <Link
            href="/apps?switch=all&price=discounted"
            className={`float-${
              isArabic ? "start" : "end"
            } font-13 font-700 color-highlight mt-n1`}
          >
            {t.home.viewAll}
          </Link>
          <div className="clearfix"></div>
        </div>
        <div
          style={{
            display: "flex",
            overflowX: "scroll",
            overflowY: "hidden",
          }}
        >
          {Children.toArray(
            featuredApps?.map((app, i) => <Name app={app} i={i} />),
          )}
        </div>
      </div>
    )
  return <div className="mb-4"></div>
}

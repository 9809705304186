import thumbnail from "public/images/Appollow-preview.png"
const submitYourApp = {
  en: {
    href: "/submit",
    title: "Submit your favorite apps now",
    subtitle:
      "You can submit your favorite apps to be featured on Appollow for free!",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/submit",
    title: "قم برفع تطبيقاتك المفضلة الآن",
    subtitle: "يمكنك رفع تطبيقاتك المفضلة لتظهر على Appollow مجانًا",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/submit",
    title: "Favori uygulamalarınızı şimdi ekleyin",
    subtitle:
      "Appollow'da öne çıkarılması için favori uygulamalarınızı ücretsiz olarak gönderebilirsiniz.",
    thumbnail: thumbnail.src,
  },
}

export default submitYourApp

import ArticlesSlider from "./ArticlesSlider"
import AppsSlider from "./AppsSlider"
import GamesSlider from "./GamesSlider"
import DiscountedSlider from "./DiscountedSlider"
import { AdUnit } from "next-google-adsense"

const MainSliders = () => {
  return (
    <>
      <ArticlesSlider />
      <AppsSlider />
      <div className="card content rounded-m overflow-hidden">
        <AdUnit
          publisherId="pub-8210764995783356"
          slotId="7645372259"
          layout="display"
        />
      </div>
      <GamesSlider />
      <DiscountedSlider />
    </>
  )
}

export default MainSliders

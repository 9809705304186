import { useEffect, useMemo } from "react"
import useSnackbarStore from "../stores/snackbar"
import { QueryKey, useInfiniteQuery } from "@tanstack/react-query"
import { AppInterface } from "../types/types"
import useT from "./useT"
import { appsList } from "../stores/appsList"
import useQueryPlus from "./useQueryPlus"

interface GetAppsQuery {
  apps: AppInterface[]
  nextIndex: number
}
interface Props {
  queryFn: ({ pageParam }: { pageParam?: number | undefined }) => Promise<any>
  queryKey?: QueryKey
  enabled?: boolean
}
export default function useFetchApps({
  queryFn,
  queryKey,
  enabled = true,
}: Props) {
  const t = useT()
  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const toggleSnackbar = useSnackbarStore((state) => state.toggleSnackbar)
  const { data: initialData } = useQueryPlus<{
    initialApps: AppInterface[]
    initialGames: AppInterface[]
    initialAllApps: AppInterface[]
    initialDiscounted: AppInterface[]
  }>({
    enabled: false,
    queryKey: ["initialApps"],
  })

  const initialApps = initialData?.initialApps
  const initialAllApps = initialData?.initialAllApps
  const initialGames = initialData?.initialGames
  const initialDiscounted = initialData?.initialDiscounted

  const getInitialData = (): AppInterface[] => {
    if (
      initialApps?.length === 0 ||
      initialAllApps?.length === 0 ||
      initialGames?.length === 0 ||
      initialDiscounted?.length === 0
    )
      return []
    if (queryKey?.includes("getAll")) return initialAllApps || []
    if (queryKey?.includes("getApps")) return initialApps || []
    if (queryKey?.includes("getGames")) return initialGames || []
    if (queryKey?.includes("getAlldiscounted")) return initialDiscounted || []
    return []
  }

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    isError,
    failureReason,
  } = useInfiniteQuery<GetAppsQuery>({
    queryKey: getInitialData().length > 0 ? queryKey : undefined,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: enabled && !!queryFn && getInitialData().length > 0,
    initialData:
      getInitialData().length > 0
        ? {
            pageParams: [0],
            pages: [{ apps: getInitialData(), nextIndex: 1 }],
          }
        : undefined,
    placeholderData: {
      pageParams: [undefined],
      pages: [{ apps: appsList, nextIndex: 1 }],
    },
    getNextPageParam: (lastPage) => lastPage.nextIndex,
  })

  useEffect(() => {
    if (isError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error?.includes("Missing queryFn for queryKey")) return
      console.log({ error, failureReason })
      toggleToast({
        text: t.snackbar.error,
        color: "red",
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorMessage = error?.message || failureReason?.message
      errorMessage &&
        toggleSnackbar({
          text: errorMessage,
          color: "red",
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error, failureReason])

  const apps = useMemo(() => data?.pages?.flatMap((page) => page.apps), [data])

  return {
    data: apps,
    fetchNextPage,
    refetch,
    isFetchingNextPage: hasNextPage && isFetchingNextPage,
  }
}

import thumbnail from "./thumbnail.webp"
const BestAudioApps = {
  en: {
    href: "/articles/best-audio-trimmer-editor-apps-for-iphone-ipad",
    title: "Best Audio Trimmer and Editor Apps",
    subtitle: "Top 4 Audio Trimmer and Editor Apps for iPhones and iPads",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/best-audio-trimmer-editor-apps-for-iphone-ipad",
    title: "افضل تطبيقات قص وتعديل الصوت",
    subtitle: "أفضل 4 تطبيقات لقص وتعديل وتحرير الصوتيات للآيفون والآيباد",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/best-audio-trimmer-editor-apps-for-iphone-ipad",
    title: "En İyi Ses Kırpma ve Düzenleyici Uygulamaları",
    subtitle:
      "iPhone'lar ve iPad'ler için En İyi 4 Ses Kırpma Düzeltici ve Düzenleyici Uygulamaları.",
    thumbnail: thumbnail.src,
  },
}

export default BestAudioApps

import thumbnail from "./thumbnail.jpg"
const BestQuranApps = {
  en: {
    href: "/articles/best-quran-apps-for-iphone-ipad",
    title: "Best Quran Reading Apps",
    subtitle:
      "Check out our picks for the best Quran apps for iPhone and iPad.",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/best-quran-apps-for-iphone-ipad",
    title: "افضل تطبيقات لقراءة القرآن للايفون والايباد",
    subtitle:
      "افضل البرامج المجانية لقراءة القرآن الكريم كاملاً على الايفون والايباد بدون انترنت",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/best-quran-apps-for-iphone-ipad",
    title: "En İyi Kuran Okuma Uygulamaları",
    subtitle:
      "iPhone ve iPad için en iyi Kuran uygulamaları seçimlerimize göz atın.",
    thumbnail: thumbnail.src,
  },
}

export default BestQuranApps

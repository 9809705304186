import thumbnail from "./thumbnail.webp"
const BestInstagramApps = {
  en: {
    href: "/articles/best-instagram-apps",
    title: "Apps and tools for Instagram",
    subtitle:
      "If you want to get the most out of Instagram, there are some tools that can help with your profile.",
    thumbnail: thumbnail.src,
  },
  ar: {
    href: "/articles/best-instagram-apps",
    title: "تطبيقات وادوات لمساعدتك في Instagram",
    subtitle:
      "إذا كنت ترغب في تحقيق أقصى استفادة من Instagram ، فهناك بعض التطبيقات التي يمكن أن تساعدك في ملفك الشخصي. ",
    thumbnail: thumbnail.src,
  },
  tr: {
    href: "/articles/best-instagram-apps",
    title: "Sana yardımcı olacak Instagram uygulamaları",
    subtitle:
      "Instagram'dan en iyi şekilde yararlanmak istiyorsanız, profilinize yardımcı olabilecek bazı uygulamalar var.",
    thumbnail: thumbnail.src,
  },
}

export default BestInstagramApps
